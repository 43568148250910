const NofFound = () => {
  return (
    <div className="fixed inset-0   text-[#3D3D3D] flex flex-col justify-center">
      <div className="p-4 flex flex-col pb-24  max-w-sm w-full mx-auto">
        <div className="flex flex-col rounded-3xl pb-12">
          <div className="font-bold text-7xl sm:text-8xl">404</div>
          <div className="text-xl sm:text-2xl">Page not found.</div>
        </div>
        <button
          onClick={() => window?.history.back()}
          className=" bg-[#3888C5] px-8 py-3 w-full font-bold rounded-lg text-white uppercase"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default NofFound;
