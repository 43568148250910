import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CodeContent from "src/components/modules/channel/code";
import Failed from "src/components/modules/channel/failed";
import GcashContent from "src/components/modules/channel/gcash";
import Loading from "src/components/modules/channel/loading";
import useCustomSWR from "src/hooks/useCustomSWR";
import useMetaTitle from "src/hooks/useMetaTitle";
import { Transaction } from "src/models/Transaction";

const ReferenceNoPage = () => {
  const params = useParams();
  const [hostName, setHostName] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  const { data, error }: { data: Transaction | null; error: any } =
    useCustomSWR(
      params.reference_no && hostName && isError === false
        ? hostName === process.env.REACT_APP_HOSTNAME
          ? `${process.env.REACT_APP_API_URL_PROD}/v1/qrph?reference_no=${params.reference_no}`
          : `${process.env.REACT_APP_API_URL_DEV}/v1/qrph?reference_no=${params.reference_no}`
        : null,
      { refreshInterval: 3000 }
    );

  useEffect(() => {
    const newHostName = window.location.hostname;
    if (newHostName) {
      setHostName(newHostName);
    }
  }, []);

  useEffect(() => {
    if (error) {
      setIsError(true);
    }
  }, [error]);

  useMetaTitle(
    data?.channel.name ? `Pay with ${data?.channel.name} via QRPh` : "QRPh"
  );

  if (data && data.channel.slug !== "gcash-qrph") {
    return <CodeContent transactionData={data} />;
  }

  if (data && data.channel.slug === "gcash-qrph") {
    return <GcashContent transactionData={data} />;
  }

  if (error || isError) {
    return <Failed />;
  }

  return <Loading />;
};

export default ReferenceNoPage;
