import { QRCodeSVG } from "qrcode.react";
import Status from "../../common/Status";
import { Transaction } from "src/models/Transaction";
import addComma from "src/utils/addComma";
import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  transactionData: Transaction;
}

const QRContent = ({ transactionData }: Props) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      toPng(qrCodeRef.current, {
        cacheBust: false,
        width: 494,
        height: 500,
        style: {
          background: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download =
            transactionData.channel.slug + "-" + transactionData.reference_no;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const handleStatus = () => {
      const status = transactionData.status;
      switch (status) {
        case "completed":
          window.location.href = transactionData?.success_redirect_url;
          break;
        case "cancelled":
          window.location.href = transactionData?.failure_redirect_url;
          break;
        default:
          break;
      }
    };

    const handleCountDown = setTimeout(() => {
      if (
        ((transactionData?.failure_redirect_url &&
          transactionData.status === "cancelled") ||
          (transactionData?.success_redirect_url &&
            transactionData.status === "completed")) &&
        countdown > 0
      ) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    if (countdown <= 0) {
      handleStatus();
      clearTimeout(handleCountDown);
    }

    return () => {
      clearTimeout(handleCountDown);
    };
  }, [countdown, transactionData]);

  return (
    <>
      <div
        className={[
          "relative w-full bg-[#fff] rounded-md text-center  pb-8 lg:pb-12 z-10",
          transactionData.channel.slug === "gcash-qrph"
            ? "shadow-lg"
            : "border border-[#E6EAED] ",
        ].join(" ")}
      >
        <div className="absolute top-[-2.5rem] left-1/2 -translate-x-1/2 max-w-[293px] w-full  z-50 ">
          <AnimatePresence>
            {transactionData?.failure_redirect_url &&
              transactionData.status === "cancelled" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  className=" text-[#3D3D3D] bg-white w-full text-xs px-7 py-5 rounded-xl shadow-[0px_4px_30px_0px_rgba(0,_0,_0,_0.10)]"
                >
                  {transactionData?.failure_redirect_url &&
                    transactionData.status === "cancelled" &&
                    `The transaction was unsuccessful. Please try again. Meanwhile, you will be redirected back to the merchant page in ${countdown} seconds.`}
                </motion.div>
              )}
          </AnimatePresence>

          <AnimatePresence>
            {transactionData?.success_redirect_url &&
              transactionData.status === "completed" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  className=" text-[#3D3D3D] bg-white w-full text-xs px-7 py-5 rounded-xl shadow-[0px_4px_30px_0px_rgba(0,_0,_0,_0.10)]"
                >
                  {transactionData?.success_redirect_url &&
                    transactionData.status === "completed" && (
                      <p>
                        Your payment is successful. Redirection <br /> to
                        merchant page in {countdown} sec...
                      </p>
                    )}
                </motion.div>
              )}
          </AnimatePresence>
        </div>

        <div className="font-bold text-2xl lg:text-3xl pt-4 pb-3 lg:pt-10 lg:pb-4 rounded-t-md border-b border-[#E6EAED]">
          <span>PHP {addComma((transactionData.amount || 0).toFixed(2))}</span>{" "}
          <br />
          <label className=" text-base lg:text-xl opacity-50 font-semibold">
            Amount Due
          </label>
        </div>
        <div className="flex flex-col space-y-4 lg:space-y-6 pt-4 lg:pt-6 items-center px-4">
          <Status status={transactionData.status} />

          {/* <canvas ref={canvasRef} width={285} height={330} /> */}
          <div
            ref={qrCodeRef}
            className="flex items-center flex-col space-y-6 w-full"
          >
            <p className=" text-base lg:text-xl font-bold">
              Scan using{" "}
              <span
                className={
                  transactionData.channel.slug === "gcash-qrph"
                    ? "text-[#0057e4]"
                    : ""
                }
              >
                {transactionData.channel.name}
              </span>{" "}
              app to pay
            </p>

            <QRCodeSVG
              className=" max-w-[80vw] object-contain"
              value={transactionData.qrcode}
              height={266}
              width={266}
              level="H"
              imageSettings={{
                src: "/images/qrph.svg",
                width: 30,
                height: 30,
                excavate: true,
              }}
            />
          </div>

          <div className="font-bold text-sm lg:text-base">OR</div>

          <button
            type="button"
            onClick={downloadQRCode}
            rel="noreferrer"
            className="bg-[#0057e4] rounded-[1.6875rem] text-white text-sm lg:text-base max-w-[22.1875rem] font-bold w-full py-2 lg:py-4"
          >
            DOWNLOAD QR
          </button>
        </div>
      </div>
    </>
  );
};

export default QRContent;
